<template>
  <div class="Article">
    <el-container>
      <el-menu
        default-active="1"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
      >
        <el-menu-item index="1" @click="reload('首页')">
          <span slot="title" :class="messageone == '首页' ? 'check_active' : ''"
            >首页</span
          >
        </el-menu-item>
        <el-submenu index="2">
          <template slot="title">
            <span>产品页</span>
          </template>
          <el-menu-item
            v-for="(item, index) in productList"
            :key="index"
            :class="index"
            :index="'2-' + index"
            @click="reload(item.name)"
            ><span class="choose">{{ item.name }}</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <span>节日福利页</span>
          </template>
          <el-menu-item
            v-for="(item, index) in welfareList"
            :key="index"
            :class="index"
            :index="'3-' + index"
            @click="reload(item.name)"
            ><span class="choose">{{ item.name }}</span>
          </el-menu-item>
        </el-submenu>
         <el-submenu index="4">
          <template slot="title">
            <span>计算器</span>
          </template>
          <el-menu-item
            v-for="(item, index) in calculatorList"
            :key="index"
            :class="index"
            :index="'4-' + index"
            @click="reload(item.name)"
            ><span class="choose">{{ item.name }}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="5" @click="reload('app下载页')">
          <span slot="title" >app下载页</span
          >
        </el-menu-item>
        <el-submenu index="6">
          <template slot="title">
            <span>城市1</span>
          </template>
          <el-menu-item
            v-for="(item, index) in city1List"
            :key="index"
            :class="index"
            :index="'6-' + index"
            @click="reload(item.name)"
            ><span class="choose">{{ item.name }}</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="7">
          <template slot="title">
            <span>城市2</span>
          </template>
          <el-menu-item
            v-for="(item, index) in city2List"
            :key="index"
            :class="index"
            :index="'7-' + index"
            @click="reload(item.name)"
            ><span class="choose">{{ item.name }}</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="8">
          <template slot="title">
            <span>城市3</span>
          </template>
          <el-menu-item
            v-for="(item, index) in city3List"
            :key="index"
            :class="index"
            :index="'8-' + index"
            @click="reload(item.name)"
            ><span class="choose">{{ item.name }}</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="9">
          <template slot="title">
            <span>城市4</span>
          </template>
          <el-menu-item
            v-for="(item, index) in city4List"
            :key="index"
            :class="index"
            :index="'9-' + index"
            @click="reload(item.name)"
            ><span class="choose">{{ item.name }}</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="10">
          <template slot="title">
            <span>城市5</span>
          </template>
          <el-menu-item
            v-for="(item, index) in city5List"
            :key="index"
            :class="index"
            :index="'10-' + index"
            @click="reload(item.name)"
            ><span class="choose">{{ item.name }}</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
      <el-main>
        <div class="titletop">
          <p style="color: red">Banner文章：0 &lt; 序号&lt;200</p>
          <p style="color: red">社保代缴城市落地页：0&lt;序号&lt;200</p>
          <p style="color: red">社保托管城市落地页：100&lt;序号&lt;200</p>
        </div>
        <div class="titletop" style="margin-left: 300px">
          <p style="color: red">热门文章：200&lt;序号&lt;400</p>
          <p style="color: red">热门操作文档：400&lt;序号&lt;500</p>
          <p style="color: red">热门常见问题：500&lt;序号&lt;600</p>
        </div>        
        <Content :KeyWords.sync="messageone" v-if="isfresh"></Content
      ></el-main>
    </el-container>
  </div>
</template>
<script>
import Content from "../../components/page";

export default {
  components: {
    Content,
  },
  provide: function () {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      productList: [
        { name: "产品与服务" },
        { name: "员工花名册" },
        { name: "企业社保代缴" },
        { name: "社保账户托管" },
        { name: "电子工资条" },
        { name: "薪资代发" },
        { name: "节日福利" },
        { name: "智能入职" },
        { name: "员工商保"},
        { name: "积分福利"},
        { name: "员工学习"},
      ],
      welfareList: [
        { name: "春节福利" },
        { name: "妇女节福利" },
        { name: "端午节福利" },
        { name: "中秋节福利" },
      ],
      calculatorList:[
        {name:'社保计算器'},
        {name:'个税计算器'},
        {name:'养老金计算器'},
      ],
      city1List: [
        { name: "深圳" },
        { name: "北京" },
        { name: "广州" },
        { name: "上海" },
        { name: "杭州" },
        { name: "天津" },
        { name: "成都" },
        { name: "重庆" },
        { name: "长沙" },
        { name: "东莞" },
      ],
      city2List: [
        { name: "佛山" },
        { name: "合肥" },
        { name: "济南" },
        { name: "南京" },
        { name: "宁波" },
        { name: "青岛" },
        { name: "沈阳" },
        { name: "苏州" },
        { name: "武汉" },
        { name: "西安" },
      ],
      city3List: [
        { name: "厦门" },
        { name: "郑州" },
        { name: "常州" },
        { name: "长春" },
        { name: "大连" },
        { name: "福州" },
        { name: "贵阳" },
        { name: "哈尔滨" },
        { name: "海口" },
        { name: "惠州" },
      ],
      city4List: [
        { name: "嘉兴" },
        { name: "昆明" },
        { name: "兰州" },
        { name: "南昌" },
        { name: "南宁" },
        { name: "南通" },
        { name: "泉州" },
        { name: "绍兴" },
        { name: "石家庄" },
        { name: "太原" },
      ],
      city5List: [
        { name: "苏州园区" },
        { name: "苏州昆山" },
        { name: "乌鲁木齐" },
        { name: "无锡" },
        { name: "芜湖" },
        { name: "温州" },
        { name: "扬州" },
        { name: "银川" },
        { name: "中山" },
        { name: "珠海" },
      ],
      messageone: "首页",
      isfresh: true,
    };
  },
  methods: {
    reload(name) {
      this.messageone = name;
      this.isfresh = false;
      this.$nextTick(() => {
        this.isfresh = true;
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style lang="stylus" scoped>
.Article {
  .el-menu {
    width: 180px;
    height: 100%;
    max-height: 100vh;
    min-height: 720px;
    overflow: auto;
    background: #fff;

    .el-submenu .el-menu-item {
      min-width: 100px;
    }
  }

  .el-main {
    background: #fff;
    position: relative;

    .titletop {
      position: absolute;
      left: 155px;
      border: 1px solid #eee;
      padding: 5px;
    }
  }

  .check_active {
    color: #409eff;
  }
}
</style>